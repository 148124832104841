.login-wap-fe {
  .btn-custom-login {
    border-radius: 25px;
    border: none;
    background: #eb0021;
  }

  .card {
    box-shadow: none;
  }

  .login_box-wrap {
    height: 230px;
    background: url("../../img/bg-login-4.png") center center no-repeat;
    background-size: 100% 100%;
  }

  .custom-form-input {
    height: 66px;
    border: 1px solid #777c92;
    border-radius: 17px;
    position: relative;
    display: flex;
    align-items: center;

    label {
      position: absolute;
      top: -10px;
      padding: 0px 15px;
      left: 20px;
      background-color: #fff;
    }

    input {
      border: none;
      width: 100%;
      height: auto;
      padding: 0;
    }
  }
}

.container-wap {
  padding-bottom: 48px;
}

.menu-bottom-wap {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  padding: 2px 0;
  box-shadow: 0 0.53333vw 2.13333vw 0 rgba(0, 0, 0, 0.25);
  height: 57px;

  a {
    text-decoration: none;
    color: #747b88;
    display: flex;
    flex-direction: column;
    font-size: 11px;
    font-weight: 400;
    justify-content: space-between;
    gap: 6px;
    i {
      font-size: 20px;
    }
  }
}

.home-wap {
  background-color: #fafafa;

  .home_top {
    position: sticky;
    top: 0;
    z-index: 99999999;
    height: 80px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eb0021;
    font-size: 13px;
  }

  .main {
    padding: 15px;

    .banner-slide {
      img {
        border-radius: 1.5vw;
        width: 100%;
        height: 100%;
        max-height: 400px;
        object-fit: cover;
      }
    }

    .box-info {
      box-shadow: 0 1.06667vw 2.13333vw rgba(0, 0, 0, 0.04);
      border-radius: 5px;
      background-color: #fff;

      .avatar {
        border-radius: 50%;
      }
    }

    .box-games {
      .box-tab {
        flex-grow: 0;
        flex-shrink: 0;
        width: 25vw;

        img {
          width: 100%;
        }
      }

      .box-game {
        width: 100%;
        flex: 1;
        overflow: hidden;
        border-radius: 2.67vw;

        img {
          width: 100%;
        }

        .container-box-game {
          overflow-y: auto;
          height: calc(100vh - 48px - 15px - 172px - 55px - 32px);
        }
      }
    }
  }

  .box-2-wap {
    margin-top: 30px;
    background: #f7f7f7;
    padding: 20px 10px;
    border-radius: 20px;
  }
}

.page-wap-game {
  background-color: #fff;

  .home_top {
    position: sticky;
    top: 0;
    z-index: 99999999;
    height: 80px;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #eb0021;
    font-size: 13px;
  }

  .keyquay {
    border-bottom: 1px solid #c8d0e6;
    padding: 4px 15px;

    .time {
      div {
        background-color: #4f3b70;
        font-size: 22px;
        border-radius: 5px;
        color: #fff;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .ketqua {
    border-bottom: 1px solid #c8d0e6;
    padding: 4px 15px;

    .box-items {
      gap: 4px;

      .item {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        // background-color: red;
        border-radius: 5px;
        color: #fff;

        &.so-1 {
          background-color: #e6de00;
        }

        &.so-2 {
          background-color: #4089f7;
        }

        &.so-3 {
          background-color: #4d4d4d;
        }

        &.so-4 {
          background-color: #eb7b30;
        }

        &.so-5 {
          background-color: #17e1e1;
        }

        &.so-6 {
          background-color: #4b20f5;
        }

        &.so-7 {
          background-color: #b8b8b8;
        }

        &.so-8 {
          background-color: #e73323;
        }

        &.so-9 {
          background-color: #6a110a;
        }

        &.so-0 {
          background-color: #63be38;
        }
      }
    }
  }

  .box-top {
    .left {
      border-radius: 10px;
      padding: 10px;
      border: 1px solid #ccc;
    }
  }

  .box-ketqua-1 {
    .ctn {
      border-radius: 10px;
      border: 1px solid #ccc;
    }
  }

  .box-bi-quay-so {
    div {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      font-size: 14px;
      color: #fff;
      background-color: red;

      &.xanh {
        background-color: green;
      }
    }
  }

  .item-box-dat-cuoc-1 {
    .title {
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ccc;
      font-size: 14px;
      font-weight: bold;
    }

    .tab-header {
      display: flex;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
      height: 47px;
      align-items: center;
      padding: 8px;

      .name-title {
        width: 30%;
        font-size: 14px;
        text-align: center;
      }

      .rate-title {
        width: 30%;
        font-size: 14px;
        text-align: center;
      }

      .amount-title {
        width: 40%;
        font-size: 14px;
        text-align: center;
      }
    }

    .box-content {
      padding: 8px;
      border: 1px solid #ccc;

      .name {
        width: 30%;
        font-size: 14px;
        text-align: center;
      }

      .rate {
        width: 30%;
        font-size: 14px;
        text-align: center;
      }

      .input {
        width: 40%;
        font-size: 14px;
        text-align: center;
      }

      input {
        outline: none;
        max-width: 100%;
        padding: 3px;
        outline: none;
        border: 1px solid #ddd;
        box-sizing: border-box;
        width: 100%;
      }

      .btn-submit-1 {
        display: flex;
        outline: none;
        border: none;
        width: 100%;
        margin: 10px auto;
        padding: 8px;
        font-weight: 600;
        background: #eb0021;
        color: #fff;
        text-transform: uppercase;
        box-sizing: border-box;
        font-size: 14px;
        justify-content: center;
      }
    }
  }

  .main-content-game {
    .header-tab {
      display: flex;
      justify-content: space-between;
      height: 42px;
      color: #aa8398;
      font-size: 14px;
      align-items: center;
      background-color: #f6f1f1;
      box-shadow: 0 0.53333vw 0.53333vw 0 rgba(0, 0, 0, 0.11);

      div {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;

        &.active {
          background-color: #eb0021;
          color: #fff;
        }
      }
    }

    .tab1 {
      // height: calc(100vh - 279px);
      overflow-y: auto;

      .sidebar-tab1 {
        width: 25%;
        flex-grow: 0;
        flex-shrink: 0;
        background-color: #fafafa;
        height: 100%;

        div {
          background-color: #fafafa;
          font-size: 14px;
          color: #aa8398;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #aa8398;

          &.active {
            color: #fff;
            background-color: #b27496;
            border-bottom: 1px solid #aa8398;
          }
        }
      }

      .content-tab1 {
        width: 75%;
        padding: 10px;
        overflow-y: auto;

        .box-item {
          margin-bottom: 20px;

          .box-heading {
            color: #666;
            font-size: 14px;
          }

          .item {
            font-size: 13px;
            border: 1px solid #9285a1;
            border-radius: 7px;
            background-color: #fff;
            text-align: center;
            height: 48px;
            align-content: center;

            .name {
              // font-weight: 600;
            }

            .rate {
              color: #999;
            }

            &.active {
              background-color: #b27496;
              border-color: #b27496;
              color: #fff;

              .name {
                color: #fff;
              }

              .rate {
                color: #fff;
              }
            }
          }
        }
      }
    }

    .tab3 {
      min-height: calc(100vh - 165px);

      .header-class {
        height: 40px;
        display: flex;
        align-items: center;
      }

      .content-class {
        height: 34px !important;

        .item-bi-quay {
          gap: 3px;
          align-items: center;

          .item {
            width: 18px;
            height: 21px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 10px;
            border-radius: 4px;
            font-family: Helvetica;
            color: #fff;
            text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.5);

            &.item-0 {
              background-color: #e4e202;
            }

            &.item-1 {
              background-color: #0492da;
            }

            &.item-2 {
              background-color: #979797;
            }

            &.item-3 {
              background-color: #f7770a;
            }

            &.item-4 {
              background-color: #1edee9;
            }

            &.item-5 {
              background-color: #4f32fe;
            }

            &.item-6 {
              background-color: #bfbfbf;
            }

            &.item-7 {
              background-color: #ff2401;
            }

            &.item-8 {
              background-color: #770b00;
            }

            &.item-9 {
              background-color: #03bc00;
            }
          }
        }
      }
    }

    .tab4 {
      min-height: calc(100vh - 165px);

      .box-list {
        .item {
          background-color: #fff;

          .header-item {
            border: 1px solid #d3dfeb;
          }

          .content-item {
            font-size: 14px;
            color: #333;
          }
        }
      }
    }

    .footer-custom-game {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 112px;
      box-shadow: 0 0.53333vw 2.13333vw 0 rgba(0, 0, 0, 0.25);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #fff;

      .footer-top {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        padding: 10px;

        .footer-top-left {
          display: flex;
          gap: 10px;

          div {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #494949;
            background-size: 100% 100%;
            font-weight: 600;

            &.item1 {
              background-image: url("../../img/c1.png");
            }

            &.item2 {
              background-image: url("../../img/c2.png");
            }

            &.item3 {
              background-image: url("../../img/c3.png");
            }

            &.item4 {
              background-image: url("../../img/c4.png");
            }

            &.item5 {
              background-image: url("../../img/c5.png");
            }

            &.item6 {
              background-image: url("../../img/c6.png");
            }
          }
        }

        .footer-top-right {
          input {
            display: flex;
            justify-content: center;
            align-items: center;
            color: #333;
            text-align: center;
            outline: none !important;
            box-shadow: none !important;
            width: 100px;
            border-radius: 7px;
            border: 1px solid #bfbfbf;
            height: 35px;
          }
        }
      }

      .footer-bottom {
        justify-content: space-between;
        padding-left: 10px;
        align-items: center;

        .button-submit {
          display: flex;

          .x3 {
            width: 87px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ad0d24;
            color: #fff;
            font-size: 14px;
            height: 53px;
          }

          .submit {
            width: 118px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #e8e8e8;
            color: #bbb;
            font-size: 14px;
            height: 53px;

            &.active {
              background-color: #eb0021;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.custom-modal-game {
  .modal-footer {
    flex-wrap: unset;
  }

  .submit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eb0021;
    color: #fff;
    font-size: 14px;
    height: 43px;
    border-radius: 5px;
  }

  .cancel {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e8e8e8;
    color: #bbb;
    font-size: 14px;
    height: 43px;
    border-radius: 5px;
  }
}

.box-account-wap {
  background-color: #fafafa;
  min-height: calc(100vh - 48px);

  .header-top {
    min-height: 250px;
    background-color: #eb0021;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .avatar {
      width: 86px;
      height: 86px;
      border-radius: 50%;
    }

    .bottom {
      padding: 10px 20px;
      background: linear-gradient(90deg, #b92539, #a00f22);

      .left {
        width: 100%;
        border-right: 1px solid #eb0021;
      }

      .right {
        width: 100%;
      }

      .box-nap-rut {
        a {
          padding: 0 5px;
          border-radius: 3px;
          color: #eb0021;
          background: linear-gradient(180deg, #fff, #f7dbf3);
        }
      }
    }
  }

  .main-content-account {
    padding: 16px;
  }

  .tab-header {
    display: flex;
    justify-content: space-between;
    border: 1px solid #d3dfeb;
    background-color: #fff;
    height: 84px;
    border-radius: 5px;

    .tab-header-item {
      width: 100%;
      color: #333;
      font-size: 14px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2px;

      &::after {
        content: "";
        width: 55%;
        height: 2px;
        background-color: #fff;
        display: block;
      }

      &.active {
        &::after {
          background-color: #eb0021;
        }
      }

      img {
        width: 35px;
        height: 35px;
      }
    }
  }

  .tab-content {
    border: 1px solid #d3dfeb;
    margin-top: 10px;
    border-radius: 5px;

    a {
      display: flex;
      justify-content: space-between;
      height: 50px;
      align-items: center;
      padding: 0 10px;
      color: #333;
      font-size: 13px;
      text-decoration: none;
      border-bottom: 1px solid #d3dfeb;
    }
  }

  .logout {
    button {
      width: 100%;
      border-radius: 30px;
      background: #eb0021;
      height: 55px;
      margin-top: 30px;
    }
  }
}

.box-history-order-wap {
  min-height: 100vh;
  background-color: #f6f6f6;

  .box-header {
    background: #eb0021 !important;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.box-chat-customer-wap {
  .chat-info-header {
    background: #eb0021 !important;
  }

  .menu-bottom-wap {
    display: none !important;
  }

  .info-current-user {
    display: none !important;
  }

  .message-box .chat-box-container {
    height: calc(100dvh - 128px) !important;
  }

  .container-wap {
    padding-bottom: 0 !important;
  }
}

.box-withdraw-wap {
  .form-withdraw {
    border-bottom: 1px solid #d3dfeb;
  }

  .amount {
    border: none !important;
    outline: none !important;
    background: transparent !important;
  }

  .box-header {
    background: #eb0021 !important;
  }

  .submit {
    background: #eb0021;
    border: none;
    box-shadow: none !important;
  }
}

.box-add-bank-wap {
  .form-group-custom {
    border-bottom: 1px solid #d3dfeb;
  }

  input {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
  }

  .box-header {
    background: #eb0021 !important;
  }

  .submit {
    background: #eb0021;
    border: none;
    box-shadow: none !important;
  }
}

.box-account-info-wap {
  min-height: 100vh;
  background-color: #fafafa;

  .content-account-info-wap {
    padding: 16px;
    color: #000;
    background-color: #fff;

    .account-top {
      height: 87px;
    }

    .tab-content {
      border: 1px solid #d3dfeb;
      margin-top: 10px;
      background-color: #fff;
      border-radius: 3px;

      a {
        display: flex;
        justify-content: space-between;
        height: 50px;
        align-items: center;
        padding: 0 10px;
        color: #333;
        font-size: 13px;
        text-decoration: none;
        border-bottom: 1px solid #d3dfeb;
      }
    }
  }
}

.box-uu-dai-wap {
  .box-header {
    background: #eb0021 !important;
    min-height: 50px;
    padding: 10px 20px;
  }

  .content-uu-dai-wap {
    padding: 16px;

    .item {
      box-shadow: #d5c0c0 0 0 5px 0;
      margin-bottom: 15px;
      border-radius: 7px;

      p {
        color: #000;
        text-align: center;
        display: block;
        padding: 10px;
      }

      img {
        border-radius: 7px 7px 0 0;
      }
    }
  }
}

.box-history-order-wap {
  .box-header {
    background: #eb0021 !important;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box-history {
    margin-top: 10px;

    .item {
      background-color: #fff;

      .header-item {
        border-bottom: 1px solid #d3dfeb;
      }

      .content-item {
        font-size: 14px;
        color: #333;
      }
    }
  }
}

.ketqua-lotto {
  .item {
    width: 35px !important;
    height: 35px !important;
    border-radius: 50% !important;
    background: linear-gradient(159deg, #d2a2bb, #995c7c) !important;
  }
}

.ketqua-f1 {
}

.box-item-f1 {
  .item {
    height: 75px !important;
  }
}

.marquee {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.marquee span {
  display: inline-block;
  animation: marquee 12s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100vw);
  }

  100% {
    transform: translateX(-100%);
  }
}

.custom-modal-when-login {
  .btn.btn-primary {
    background: linear-gradient(
      93.19deg,
      rgba(82, 49, 98, 0.85),
      rgba(129, 74, 149, 0.85) 48.92%,
      rgba(201, 133, 145, 0.85) 98.86%
    ) !important;
  }

  .bg,
  .modal-content {
    border-radius: 20px;
  }

  .title-c {
    font-size: 27px;
    font-weight: 700;
    color: #ff7f69;
    text-shadow: 0.3vw 0.3vw rgba(255, 127, 105, 0.35);
    padding: 17px;
    margin-bottom: 2vw;
  }

  .modal-body {
    padding: 0 !important;
  }

  .content-c {
    max-height: 300px;
    padding: 0 17px;
    overflow-y: auto;
  }
}

.content-account-info-wap {
  .text-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #9d734c;
  }

  .text-username {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
  }
}

.box-change-passwword-wap {
  .box-header {
    background: #eb0021 !important;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  button {
    background: linear-gradient(0deg, #f49400, #ffc05f),
      linear-gradient(#3b7cec, #3b7cec) !important;
  }

  .form-group-custom {
    border-bottom: 1px solid #d3dfeb;

    input {
      border: none !important;
      background: transparent !important;
      box-shadow: none !important;
      text-align: right;
    }
  }
}

.box-withdraw-wap,
.box-add-bank-wap {
  min-height: 100vh;
  background-color: #f6f6f6;

  .box-header {
    background: #eb0021 !important;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box-content {
    padding: 16px;

    .bank {
      background-color: gray;
      border-radius: 10px;
      padding: 16px;
      color: #fff;
    }
  }

  .box-form {
    padding: 16px;

    input {
      text-align: right;
    }
  }
}

.table-history-wap {
  width: 100%;
  overflow: auto;

  .header-table {
    align-items: center;
    flex-wrap: nowrap;

    div {
      height: 100%;
      text-align: center;
      max-width: 25%;
      min-width: 114px;
      width: 100%;
      height: 37px;
      font-size: 14px;
      background-color: #eeeeee;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ddd;
    }
  }

  .content-table {
    align-items: center;
    flex-wrap: nowrap;

    div {
      height: 100%;
      border: 1px solid #ddd;
      max-width: 25%;
      min-width: 114px;
      width: 100%;
      min-height: 70px;
      font-size: 14px;
      padding: 10px;
      align-content: center;
      text-align: center;
    }
  }
}

.sap-ra-mat {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rainbow-border;
  animation-name: rainbow-border;
  background: bisque;
  border: 2px solid;
  border-radius: 5px;
  line-height: 22px;
  margin: 10px 1rem;
  padding: 5px 1.2rem;
  font-weight: 600;
}

@keyframes rainbow-border {
  0% {
    border-color: red;
    color: black;
  }

  16.666% {
    border-color: orange;
    color: red;
  }

  33.333% {
    border-color: yellow;
    color: black;
  }

  50% {
    border-color: green;
    color: red;
  }

  66.666% {
    border-color: blue;
    color: black;
  }

  83.333% {
    border-color: indigo;
    color: red;
  }

  100% {
    border-color: violet;
    color: black;
  }
}
